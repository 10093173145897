import { BrowserRouter, Routes, Route } from "react-router-dom";
import Unsubscribe from './Unsubscribe';
import './App.scss'

function App() {

  return (
    <main className="app-container">
      <BrowserRouter>
        <div className="main-container">
          <Routes>
            <Route path="/unsubscribe" exact index element={<Unsubscribe />} />
          </Routes>
        </div>
      </BrowserRouter>
    </main>
  );
}

export default App;
