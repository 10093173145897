import { CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'


const Unsubscribe = () => {

  const [pageLoading, setPageLoading] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams();
  
  useEffect(() => {
    console.log(searchParams.get("email")) 
    unsubscribe(searchParams.get("email"))
  }, [searchParams])

  const unsubscribe = (email) => {

    try {
      fetch(`https://apiv2.himalayanhaat.org/v3/unsubscribe/${email}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(response => {
          return response.json();
        }).then(data => {
          if (data.status === 'success') {
            setPageLoading(false)
          }
        }).catch(err => {
          console.error(err)
        });
    } catch (error) {
      console.log('error : ', error)
    }
  }

  if (pageLoading) {
    return <div className='unsub-container' >
      <div className="loading">
        <CircularProgress />
      </div>
    </div>
  }

  return (
    <div className='unsub-container' >
      <div className="unsub">
        <span>You have successfully unsubscribed.</span>
      </div>
    </div>
  )
}

export default Unsubscribe